

























import { Component, Vue } from 'vue-property-decorator';

import NotificationsViewModel
  from '@/vue-app/view-models/components/nav-bar/notifications/notifications-view-model';

@Component({
  name: 'Notifications',
  components: {
    NotificationItem: () => import('@/vue-app/components/nav-bar/notifications/NotificationItem.vue'),
  },
})
export default class Notifications extends Vue {
  view_model = Vue.observable(new NotificationsViewModel(this));

  created() {
    this.view_model.initialize();
  }
}
