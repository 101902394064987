import Vue from 'vue';
import TYPES from '@/types';

// Applications
import {
  GetCustomerNotificationsQuery,
} from '@/modules/customer/customer-notification/application/queries';

// Domain
import { CustomerNotification } from '@/modules/customer/customer-notification/domain/entities';
import { CustomerNotificationItem } from '@/modules/customer/customer-notification/domain/dtos';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { Router } from '@/modules/shared/domain/router';

export default class NotificationsViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.GET_CUSTOMER_NOTIFICATIONS_QUERY)
  readonly get_customer_notifications!: GetCustomerNotificationsQuery;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  readonly i18n_namespace = 'components.nav-bar.notifications';

  readonly view: Vue;

  is_loading = false;

  customer_notification_items: CustomerNotificationItem[] = [];

  constructor(view: Vue) {
    this.view = view;
  }

  get width_screen() {
    switch (this.view.$vuetify.breakpoint.name) {
      case 'xs': return '100%';
      default: return '636px';
    }
  }

  get height_screen() {
    switch (this.view.$vuetify.breakpoint.name) {
      case 'xs': return '100%';
      default: return 'auto';
    }
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  cancel = () => {
    this.view.$emit('hideNotifications');
  }

  callAction = (notification_type: string) => {
    switch (notification_type) {
      case 'ine_expired':
        this.router.navigate('/update-identity-document-authenticated');
        this.view.$emit('hideNotifications');
        break;
      default:
        break;
    }
  }

  getIconAndActionFromNotificationType = (notification_type: string) => {
    switch (notification_type) {
      case 'ine_expired':
        return {
          icon: 'mdi-card-account-details',
          action: this.translate('actions.update_ine'),
        };
      case 'customer_sowos_moderate_hire_status':
        return {
          icon: 'mdi-information-outline',
          action: '',
        };
      case 'non_business_date':
        return {
          icon: 'mdi-information-outline',
          action: '',
        };
      default: return {
        icon: 'mdi-update',
        action: '',
      };
    }
  }

  getCustomerNotificationsDtos = (customer_notifications: CustomerNotification[]) => {
    const customer_notification_dtos: CustomerNotificationItem[] = [];
    customer_notifications.forEach((customer_notification) => {
      const icon_action: {
        action: string; icon: string;
      } = this.getIconAndActionFromNotificationType(customer_notification.notification_type);
      customer_notification_dtos.push({
        ...customer_notification,
        notification_date: this.date_formatter.formatDate(customer_notification.notification_date, 'DD MMM YYYY'),
        icon: icon_action.icon,
        action: icon_action.action,
      });
    });
    return customer_notification_dtos;
  }

  loadCustomerNotifications = async () => {
    this.is_loading = true;
    try {
      const customer_notifications = await this.get_customer_notifications
        .execute();

      if (customer_notifications.length) {
        this.view.$emit('activateNotifications');
      }
      this.customer_notification_items = this.getCustomerNotificationsDtos(
        customer_notifications,
      );
    } catch (error) {
      this.customer_notification_items = [];
    } finally {
      this.is_loading = false;
    }
  }

  initialize = async () => {
    await this.loadCustomerNotifications();
  }
}
